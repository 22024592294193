<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-row class="d-flex justify-content-around text-center">
          <b-col lg="12" sm="12">
            <b-card class="col-12">
              <b-card-body>
                <b-row>
                  <b-input-group class="input-group-merge">
                    <b-form-input
                      @keyup="getList"
                      v-model="filters.q"
                      :placeholder="
                        $t('Base.Placeholder.search_ch')
                      "
                      class="search-product"
                    />
                    <b-input-group-append is-text>
                      <feather-icon icon="SearchIcon" class="text-muted" />
                    </b-input-group-append>
                  </b-input-group>
                </b-row>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
      <b-col lg="12" sm="12">
        <b-card v-if="tableData" no-body class="card-company-table">
          <b-overlay
            :show="!showTableData"
            spinner-variant="primary"
            variant="transparent"
            spinner-type="grow"
            spinner-small
            rounded="sm"
          >
            <b-table
              :items="tableData"
              responsive
              :fields="fields"
              primary-key="id"
              show-empty
              :empty-text="$t('Pages.Mentor.Table.no_ch_found')"
              class="position-relative"
            >
              <template #cell(nickname)="data">
                <b-link
                  :to="{
                    path: '/tools/checklist/info',
                    query: { id: data.item.channel_id },
                  }"
                >
                  <div class="d-flex align-items-center">
                    <b-avatar rounded size="32" variant="light-company">
                      <b-img
                        class="img-fluid"
                        :src="data.item.avatar"
                        alt="avatar img"
                      />
                    </b-avatar>
                    <div class="ml-1">
                      <div class="font-weight-bolder">
                        {{ data.item.nickname }}
                      </div>
                      <div class="font-small-2 text-muted">
                        {{ data.item.country }}
                      </div>
                    </div>
                  </div>
                </b-link>
              </template>

              <template #cell(name)="data">
                <div class="d-flex flex-column">
                  <span class="font-weight-bolder mb-25">{{
                    data.item.fullname
                  }}</span>
                </div>
              </template>

              <template #cell(subscribers)="data">
                <div class="d-flex flex-column">
                  <span class="font-weight-bolder mb-25">{{
                    data.item.subscribers
                  }}</span>
                </div>
              </template>

              <template #cell(views)="data">
                <div class="d-flex flex-column">
                  <span class="font-weight-bolder mb-25">{{
                    data.item.views
                  }}</span>
                </div>
              </template>

              <template #cell(videos)="data">
                <div class="d-flex flex-column">
                  <span class="font-weight-bolder mb-25">{{
                    data.item.videos
                  }}</span>
                </div>
              </template>

              <template #cell(date)="data">
                <div class="d-flex flex-column">
                  <span class="font-weight-bolder mb-25">{{
                    MyDate.getFormatDate(data.item.created_at)
                  }}</span>
                </div>
              </template>

              <template #cell(showSeo)="data">
                <div class="d-flex align-items-center">
                  <b-button
                    v-if="data.item.videos == 0"
                    class="btn-sm btn-warning rounded"
                  >
                    {{ $t("Pages.Mentor.Title.no_exist_vid") }}
                  </b-button>
                  <b-button
                    v-else-if="!data.item.analytics_uuid"
                    class="btn-sm btn-warning rounded"
                    @click="
                      startAnalysis(
                        data.item.channel_id,
                        data.item.videos,
                        data.item.id
                      )
                    "
                  >
                    {{ $t("Pages.Mentor.Title.show_analys_req") }}
                  </b-button>
                  <b-button
                    v-else-if="data.item.analytics_uuid == '0000'"
                    class="btn-sm btn-info rounded"
                  >
                    {{ $t("Pages.Mentor.Title.Analyzing") }}
                  </b-button>
                  <b-button
                    v-else-if="
                      data.item.analytics_uuid &&
                      data.item.analytics_uuid != '0000'
                    "
                    class="btn-sm btn-success rounded"
                    @click="
                      openAnalysis(
                        data.item.channel_id,
                        data.item.analytics_uuid
                      )
                    "
                  >
                    {{ $t("Pages.Mentor.Title.show_analys") }}
                  </b-button>
                </div>
              </template>
              <template #cell(done)="data">
                <div class="d-flex flex-column">
                  <b-button
                    v-if="data.item.done"
                    class="btn-sm btn-success rounded"
                  >
                    {{ $t("Pages.Mentor.Title.Chechked") }}
                  </b-button>
                  <b-button
                    v-else
                    @click="changeStatus(data.item.id)"
                    class="btn-sm btn-danger rounded"
                  >
                    {{ $t("Pages.Mentor.Title.not_checked") }}
                  </b-button>
                </div>
              </template>
            </b-table>
          </b-overlay>
        </b-card>
      </b-col>
      <b-col cols="12" v-if="pages > 1">
        <b-pagination-nav
          :link-gen="linkGen"
          :number-of-pages="pages"
          use-router
          class="mb-0"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BRow,
  BCol,
  BAvatar,
  BTable,
  BImg,
  BLink,
  BButton,
  BAlert,
  BBadge,
  BTooltip,
  BPaginationNav,
  BFormGroup,
  BFormInput,
  BOverlay,
  BInputGroup,
  BInputGroupAppend,
  BTabs,
  BTab,
  BMedia,
  BMediaAside,
  BMediaBody,
  BCardBody,
  BCardHeader,
  BCardSubTitle,
  BCardTitle,
  BProgress,
  BModal,
} from "bootstrap-vue";
import MyDate from "@core/components/date/MyDate.vue";

export default {
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    BAvatar,
    BTable,
    BImg,
    BLink,
    BButton,
    BAlert,
    BBadge,
    BTooltip,
    BPaginationNav,
    BFormGroup,
    BFormInput,
    BOverlay,
    BInputGroup,
    BInputGroupAppend,
    BTabs,
    BTab,
    BMedia,
    BMediaAside,
    BMediaBody,
    BCardBody,
    BCardHeader,
    BCardSubTitle,
    BCardTitle,
    BProgress,
    BModal,
  },
  data() {
    return {
      MyDate,
      tableData: [],
      fields: [
        { key: "nickname", label: this.$t("Pages.Mentor.Table.ch_name") },
        {
          key: "name",
          label: this.$t("Pages.Mentor.Table.first_last_name"),
        },
        { key: "subscribers", label: this.$t("Pages.Mentor.Table.Subscriber") },
        { key: "views", label: this.$t("Pages.Mentor.Table.View") },
        { key: "videos", label: this.$t("Pages.Mentor.Table.Videos") },
        { key: "instagram", label: this.$t("Pages.Mentor.Table.Instagram") },
        { key: "date", label: this.$t("Pages.Mentor.Table.regis_date") },
        { key: "showSeo", label: this.$t("Pages.Mentor.Table.show_analys") },
        { key: "done", label: this.$t("Pages.Mentor.Table.status_check") },
      ],
      showTableData: false,
      filters: { q: "" },
      pages: 0,
    };
  },
  created() {
    this.getList();
  },
  watch: {
    $route(to, from) {
      if (to.query != from.query) {
        this.getList();
      }
    },
  },
  methods: {
    openYoutube(channelId) {
      window.open(`https://www.youtube.com/channel/${channelId}`, "_blank");
    },
    getList() {
      let filter = this.filters.q;
      if (this.filters.q == "") {
        filter = "";
      }
      let page =
        typeof this.$route.query.page !== "undefined"
          ? this.$route.query.page
          : 1;
      this.$http
        .get(`/api/mentor/request-channel/list?filter=${filter}&page=${page}`)
        .then((response) => {
          this.tableData = response.data.data;
          this.pages = response.data.last_page;
          this.showTableData = true;
        });
    },
    linkGen(pageNum) {
      return pageNum === 1 ? "?" : `?page=${pageNum}`;
    },
    startAnalysis(channel_id, count_video, request_id) {
      this.showTableData = false;
      this.$http
        .post(
          "/api/tools/assistant/start-analysis-mentor",
          {
            channel_id: channel_id,
            count_video: count_video,
            request_id: request_id,
          },
          { timeout: 200000000 }
        )
        .then((response) => {
          if (response.status == 200) {
            this.$swal({
              title: this.$t(
                "Pages.Assistant.Title.success_app_reg"
              ),
              text: this.$t(
                "Pages.Assistant.Title.success_app_reg_txt"
              ),
              imageUrl: require("@/assets/images/assistant/review-request-man.svg"),
              imageWidth: 400,
              imageHeight: 200,
              imageAlt: "Custom image",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
              confirmButtonText: this.$t(
                "Pages.Assistant.Button.success_app_reg_btn"
              ),
            }).then((result) => {
              this.$router.push({ name: "mentor-reviews" });
              this.getList();
            });
            this.showTableData = true;
          }
        })
        .catch((error) => {
          this.makeToast(
            this.$t("Base.Alert.Error"),
            this.$t("Base.Alert.error_server"),
            "danger"
          );
          this.showTableData = true;
        });
    },
    openAnalysis(channel_id, uuid) {
      this.$router.push({
        name: "Assistant",
        params: {
          channel_id: channel_id,
          uuid: uuid,
        },
      });
    },
    changeStatus(id) {
      this.$http
        .get(`/api/mentor/request-channel/done`, {
          params: {
            id: id,
          },
        })
        .then((response) => {
          this.makeToast(
            this.$t("Base.Alert.Successful"),
            this.$t(
              "Base.Alert.success_change_status"
            ),
            "success"
          );
          this.getList();
        });
    },
  },
};
</script>
<style>
.modal-backdrop {
  opacity: 0.7 !important;
}
</style>
