<script>
import moment from 'moment'

let exports = {};

exports.getFormatDate = (date) => {
  return moment(date).format('DD/MM/YYYY')
}
exports.getFormatDateTime = (date) => {
  return moment(date).format('DD/MM/YYYY HH:mm:ss')
}

exports.getFormatDateTimeFA = (date) => {
  return moment(date).format('HH:mm:ss DD/MM/YYYY')
}

export default exports;
</script>
