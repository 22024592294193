var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-row',{staticClass:"d-flex justify-content-around text-center"},[_c('b-col',{attrs:{"lg":"12","sm":"12"}},[_c('b-card',{staticClass:"col-12"},[_c('b-card-body',[_c('b-row',[_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{staticClass:"search-product",attrs:{"placeholder":_vm.$t('Base.Placeholder.search_ch')},on:{"keyup":_vm.getList},model:{value:(_vm.filters.q),callback:function ($$v) {_vm.$set(_vm.filters, "q", $$v)},expression:"filters.q"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"text-muted",attrs:{"icon":"SearchIcon"}})],1)],1)],1)],1)],1)],1)],1)],1),_c('b-col',{attrs:{"lg":"12","sm":"12"}},[(_vm.tableData)?_c('b-card',{staticClass:"card-company-table",attrs:{"no-body":""}},[_c('b-overlay',{attrs:{"show":!_vm.showTableData,"spinner-variant":"primary","variant":"transparent","spinner-type":"grow","spinner-small":"","rounded":"sm"}},[_c('b-table',{staticClass:"position-relative",attrs:{"items":_vm.tableData,"responsive":"","fields":_vm.fields,"primary-key":"id","show-empty":"","empty-text":_vm.$t('Pages.Mentor.Table.no_ch_found')},scopedSlots:_vm._u([{key:"cell(nickname)",fn:function(data){return [_c('b-link',{attrs:{"to":{
                  path: '/tools/checklist/info',
                  query: { id: data.item.channel_id },
                }}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('b-avatar',{attrs:{"rounded":"","size":"32","variant":"light-company"}},[_c('b-img',{staticClass:"img-fluid",attrs:{"src":data.item.avatar,"alt":"avatar img"}})],1),_c('div',{staticClass:"ml-1"},[_c('div',{staticClass:"font-weight-bolder"},[_vm._v(" "+_vm._s(data.item.nickname)+" ")]),_c('div',{staticClass:"font-small-2 text-muted"},[_vm._v(" "+_vm._s(data.item.country)+" ")])])],1)])]}},{key:"cell(name)",fn:function(data){return [_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"font-weight-bolder mb-25"},[_vm._v(_vm._s(data.item.fullname))])])]}},{key:"cell(subscribers)",fn:function(data){return [_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"font-weight-bolder mb-25"},[_vm._v(_vm._s(data.item.subscribers))])])]}},{key:"cell(views)",fn:function(data){return [_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"font-weight-bolder mb-25"},[_vm._v(_vm._s(data.item.views))])])]}},{key:"cell(videos)",fn:function(data){return [_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"font-weight-bolder mb-25"},[_vm._v(_vm._s(data.item.videos))])])]}},{key:"cell(date)",fn:function(data){return [_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"font-weight-bolder mb-25"},[_vm._v(_vm._s(_vm.MyDate.getFormatDate(data.item.created_at)))])])]}},{key:"cell(showSeo)",fn:function(data){return [_c('div',{staticClass:"d-flex align-items-center"},[(data.item.videos == 0)?_c('b-button',{staticClass:"btn-sm btn-warning rounded"},[_vm._v(" "+_vm._s(_vm.$t("Pages.Mentor.Title.no_exist_vid"))+" ")]):(!data.item.analytics_uuid)?_c('b-button',{staticClass:"btn-sm btn-warning rounded",on:{"click":function($event){return _vm.startAnalysis(
                      data.item.channel_id,
                      data.item.videos,
                      data.item.id
                    )}}},[_vm._v(" "+_vm._s(_vm.$t("Pages.Mentor.Title.show_analys_req"))+" ")]):(data.item.analytics_uuid == '0000')?_c('b-button',{staticClass:"btn-sm btn-info rounded"},[_vm._v(" "+_vm._s(_vm.$t("Pages.Mentor.Title.Analyzing"))+" ")]):(
                    data.item.analytics_uuid &&
                    data.item.analytics_uuid != '0000'
                  )?_c('b-button',{staticClass:"btn-sm btn-success rounded",on:{"click":function($event){return _vm.openAnalysis(
                      data.item.channel_id,
                      data.item.analytics_uuid
                    )}}},[_vm._v(" "+_vm._s(_vm.$t("Pages.Mentor.Title.show_analys"))+" ")]):_vm._e()],1)]}},{key:"cell(done)",fn:function(data){return [_c('div',{staticClass:"d-flex flex-column"},[(data.item.done)?_c('b-button',{staticClass:"btn-sm btn-success rounded"},[_vm._v(" "+_vm._s(_vm.$t("Pages.Mentor.Title.Chechked"))+" ")]):_c('b-button',{staticClass:"btn-sm btn-danger rounded",on:{"click":function($event){return _vm.changeStatus(data.item.id)}}},[_vm._v(" "+_vm._s(_vm.$t("Pages.Mentor.Title.not_checked"))+" ")])],1)]}}],null,false,2096074089)})],1)],1):_vm._e()],1),(_vm.pages > 1)?_c('b-col',{attrs:{"cols":"12"}},[_c('b-pagination-nav',{staticClass:"mb-0",attrs:{"link-gen":_vm.linkGen,"number-of-pages":_vm.pages,"use-router":""}})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }